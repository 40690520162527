import React, {useState, useEffect, useContext} from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import Api from "../_services/api_service";
import {GlobalContext} from "../_services/global-context";
import Services from "../_services/services";
import URL from "../_services/routes";

const Register = () => {
    const [global, dispatch] = useContext(GlobalContext);
    const [processing, setProcessing] = useState(false);
    const [errors, setErrors] = useState({});

    const user_default = {first_name: '', last_name: '', email: '', contact_no: '', country_id: '', state_id: '', city: '', postal_code: '',}
    const [user, setUser] = useState(user_default);

    useEffect(() => {

    }, []);

    const handleChange = (name, value) => {
        user[name] = value;
        setUser(user);
    };

    const handleRegister = (e) => {
        e.preventDefault();
        user['pcl_id'] = global.pcl.id
        setProcessing(true)
        Api.post('users/create', user, (res) => {

            dispatch({type: "SHOW-SUCCESS", payload: 'Your account created successfully!'})
            handleLogin();

        }, (errs) => {
            setProcessing(false)
            setErrors(errs);
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errs)})
        });
    }

    const handleLogin = () => {
        const payload = {
            pcl_id: global.pcl.id,
            email: user['email'],
            password: user['password'],
            browser_id: global.browser_id,
        };

        setProcessing(true);
        Api.post('client/auth/login', payload, (res) => {
            const data = res.data.data;
            if (!data.token || data.token.trim() === '') {
                Services.redirectTo('/login', global.source)
            }

            if (data.token) {
                dispatch({type: "USER", payload: data.user});
                dispatch({type: "TOKEN", payload: data.token});
                dispatch({type: 'ORDER-CUSTOMER', payload: data.user.id});
                localStorage.setItem('user_pcl', JSON.stringify(data.pcl));

                dispatch({type: "SHOW-SUCCESS", payload: 'Logged in successfully!'})
            }else {
                dispatch({type: "SHOW-ERROR", payload: 'Sorry, Invalid login details!'})
            }

            let returning_url = global.returning_url || ''
            setTimeout(() => {
                const redirect_base = URL.getRedirectBase();
                if (URL.accessType() === 'kiosk') {
                    returning_url = redirect_base + '/waiver-sign'
                } else {
                    returning_url = (returning_url) ? redirect_base + returning_url : '/pos';
                }

                console.log('returning_url: ', returning_url)

               Services.redirectTo(returning_url, global.source)
            }, 1000)

        }, (errors) => {
            Services.redirectTo('/login', global.source)
        });
    }

    return (
        <>
            <Container>
                <div className="auth-wrapper">
                    <div className="m-auto" style={{maxWidth: '880px'}}>

                        {/*<button onClick={() => handle2FAAuth()}>Check</button>*/}
                        {/* Reset password */}
                        <div className='fw-bold text-center fs-21x mt-3'>Enter your details to create account!</div>

                        <Form onSubmit={handleRegister} autoComplete="off">

                            <Row className='mt-2'>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="first_name">
                                        <Form.Label className='req-label'>First Name</Form.Label>
                                        <Form.Control type="text" className={(errors.first_name) && 'is-invalid'} placeholder='---'
                                                      onChange={(e) => handleChange('first_name', e.target.value)}
                                                      max={14} required/>
                                        <div className="invalid-feedback">{errors.first_name}</div>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="last_name">
                                        <Form.Label className='req-label'>Last Name</Form.Label>
                                        <Form.Control type="text" className={(errors.last_name) && 'is-invalid'} placeholder='---'
                                                      onChange={(e) => handleChange('last_name', e.target.value)}
                                                      max={14} required/>
                                        <div className="invalid-feedback">{errors.last_name}</div>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label className='req-label'>Email</Form.Label>
                                        <Form.Control type="text" className={(errors.email) && 'is-invalid'} placeholder='---'
                                                      onChange={(e) => handleChange('email', e.target.value)}
                                                      max={50} required/>
                                        <div className="invalid-feedback">{errors.email}</div>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="contact_no">
                                        <Form.Label className=''>Contact</Form.Label>
                                        <Form.Control type="text" className={(errors.contact_no) && 'is-invalid'} placeholder='---'
                                                      onChange={(e) => handleChange('contact_no', e.target.value)} required/>
                                        <div className="invalid-feedback">{errors.contact_no}</div>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="password">
                                        <Form.Label className='req-label'>New Password</Form.Label>
                                        <Form.Control type="password" className={(errors.password) && 'is-invalid'} placeholder='---'
                                                      onChange={(e) => handleChange('password', e.target.value)} required/>
                                        <div className="invalid-feedback">{errors.password}</div>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="password_confirmation">
                                        <Form.Label className='req-label'>Re-enter Password</Form.Label>
                                        <Form.Control type="password" className={(errors.password_confirmation) && 'is-invalid'} placeholder='---'
                                                      onChange={(e) => handleChange('password_confirmation', e.target.value)} required/>
                                        <div className="invalid-feedback">{errors.password_confirmation}</div>
                                    </Form.Group>
                                </Col>
                            </Row>


                            <div className='text-muted fs-16x mb-2'>
                                Password must be at least 6 characters, minimum one letter and one digit is required.
                            </div>

                            <br/>
                            <div className="form-group text-end">
                                {/* Cancel button */}

                                <button type="submit" className="btn btn-success me-2"
                                        onClick={handleRegister}>Create Account {processing && <span>&nbsp;...</span>}
                                </button>

                                <Button size='md' type="button" variant="warning"
                                        onClick={() => {
                                            Services.logoutAndRedirect('/login', global.source)
                                        }}>Cancel</Button>
                            </div>

                        </Form>
                    </div>
                    <br/>
                </div>
            </Container>
        </>
    );
};

export default Register;
