import React, {useContext, useEffect, useState} from "react";
import URLr from "../_services/routes";
import Api from "../_services/api_service";
import axios from "axios";
import {GlobalContext} from "../_services/global-context";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import Services from "../_services/services";
import {cloneDeep} from "lodash";

import moment from "moment-timezone";
import Swal from "sweetalert2";
import Bookings from "./bookings";
import Select from "react-select";


export default function CartItemProperties() {
    const [global, dispatch] = useContext(GlobalContext);

    const [item, setItem] = useState({})
    const [itemId, setItemId] = useState(null)
    const [categoryId, setCategoryId] = useState(null)
    const [quantity, setQuantity] = useState(null)

    const [orderId, setOrderId] = useState(global.order_id || null)
    const [properties, setProperties] = useState([])
    const [bookings, setBookings] = useState([])
    const [orderDetailId, setOerDetailId] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [bookingsData, setBookingsData] = useState([]);

    const [propertyChange, setPropertyChange] = useState(false)

    const [errors, setErrors] = useState({});
    const [errorsArr, setErrorsArr] = useState([]);

    const b_details_default = {max_bookings: 0, max_bookings_per_day: 1, max_checkins: 1, max_bookings_required: 0, slots_auto_selection: 0}
    const [bookingDetails, setBookingDetails] = useState(b_details_default)
    const [bookingItems, setBookingItems] = useState([])
    const [selectedBookingItem, setSelectedBookingItem] = useState({})

    const order_id = URLr.urlQueryParams('order_id');
    const item_id = URLr.urlQueryParams('item_id');
    const category_id = URLr.urlQueryParams('category_id');
    const order_detail_id = URLr.urlQueryParams('order_detail_id');
    const req_quantity = URLr.urlQueryParams('quantity');

    // Effects
    useEffect(() => {
        if (order_id) {
            setOrderId(order_id)
            handleManageBookingsDetails(order_id).then(res => {
                if (res) {
                    dispatch({type: 'SHOW-SUCCESS', payload: 'Booking found for this order.'})
                } else {
                    dispatch({type: 'SHOW-ERROR', payload: 'Sorry, bookings not found for this order.'})
                }
            })
        }

        if (req_quantity) setQuantity(req_quantity)
        if (category_id) setCategoryId(category_id)

        if (item_id || itemId) {
            setItemId(item_id ?? itemId)
            handleGetItemDetails(item_id ?? itemId)

            if (order_detail_id || orderDetailId) {
                setOerDetailId(order_detail_id || orderDetailId)
                handleCartEditDetails(item_id || itemId, order_detail_id || orderDetailId)
            } else {
                handleGetProperties(item_id || itemId)
            }
        }
    }, [])

    useEffect(() => {
        if (order_id && itemId) {
            setItemId(itemId)
            handleGetItemDetails(itemId)

            if (orderDetailId) {
                setOerDetailId(orderDetailId)
                handleCartEditDetails(itemId, orderDetailId)
            } else {
                handleGetProperties(itemId)
            }
        }
    }, [itemId, orderDetailId])

    useEffect(() => {
        if (properties.length > 0) {
            const from_date = properties.find(obj => obj.slug === 'from_date');
            const max_bookings = properties.find(obj => obj.slug === 'max-bookings');
            const adm_availability = properties.find(obj => obj.slug === 'bookings-availability');

            if (from_date && from_date.cart_value) {
                bookingDetails['from_date'] = from_date.value || max_bookings.cart_value?.property_value || null
            }

            if (max_bookings && max_bookings.cart_value) {
                bookingDetails['max_bookings'] = max_bookings.cart_value?.property_value || max_bookings.cart_value
            }

            if (adm_availability && adm_availability.cart_value) {
                if (adm_availability && adm_availability.value && adm_availability.property_values) {
                    const property_value = adm_availability.property_values.find(obj => obj.id == adm_availability.value)
                    if (property_value) {
                        let avail_data = property_value.value
                        avail_data = avail_data.split(',');

                        let error = false;
                        if (avail_data.length == 2 || avail_data.length == 4) {
                            const avail_weeks = avail_data.map((week) => week.trim());
                            avail_weeks.map((week, index) => {
                                error = false
                                if (index == 2 || index == 3) {
                                    week = avail_weeks[0] + ' ' + week
                                }

                                const parsedDateTime = moment(week, undefined, true);
                                const valid = parsedDateTime.isValid();
                                if (!valid) {
                                    error = true;
                                }
                            })

                            if (!error) {
                                if (avail_data[0] && avail_data[1]) {
                                    bookingDetails['from_date'] = avail_data[0]
                                    bookingDetails['to_date'] = avail_data[1]
                                }

                                if (avail_data[2] && avail_data[3]) {
                                    bookingDetails['from_time'] = avail_data[2]
                                    bookingDetails['to_time'] = avail_data[3]
                                }

                            }
                        }
                    }
                }
            }
            setBookingDetails({...bookingDetails})
        }
    }, [bookingsData])


    useEffect(() => {
        if (orderDetailId && (!global.order_id && !order_id)) {
            dispatch({type: "SHOW-ERROR", payload: 'Sorry your order has been expired.'});
            setTimeout(() => {
                window.location.assign('/');
            }, 3000);
        }
    }, [global.order_id])


    const handleManageBookingsDetails = async (order_id) => {
        let params = {
            pcl_id: global.pcl.id,
            order_id: order_id,
        }

        let apiUrl = Api.getApiURL();
        const qParams = "?" + new URLSearchParams(params).toString();

        // Request to get spaces slots
        const res = await axios.get(apiUrl + `client/orders/${order_id}/booking-items` + qParams);
        const data = res.data.data;

        const b_items = [];
        data.map((detail, index) => {
            const item = {value: detail.id, label: detail.item_name, item_id: detail.item_id}
            if (index === 0) {
                setItemId(detail.item_id)
                setOerDetailId(detail.id)
                setSelectedBookingItem(item)
            }
            b_items.push(item)
        })
        setBookingItems([...b_items]);
        return res;
    }

    // To get item details
    const handleGetItemDetails = (item_id) => {
        const params = "name,event_id,max_bookings,max_bookings_per_day,max_bookings_required,max_checkins,advance_booking_days,slots_auto_selection,booking_confirmations,description_short";
        let qryParams = {pcl_id: global.pcl.id, params: params,}

        const qParams = "?" + new URLSearchParams(qryParams).toString();
        Api.get(`client/items/${item_id}` + qParams, (res) => {
            const data = res.data.data;
            setItem(data);

            const booking_details = data;
            booking_details['max_bookings'] = data.max_bookings || 0;
            booking_details['max_checkins'] = data.max_checkins || 1;
            booking_details['max_bookings_per_day'] = data.max_bookings_per_day || 1;
            booking_details['max_bookings_required'] = data.max_bookings_required || 0;
            booking_details['slots_auto_selection'] = data.slots_auto_selection || 0;
            booking_details['advance_booking_days'] = data.advance_booking_days || 0;
            console.log('booking_details: ', booking_details);
            setBookingDetails({...booking_details});
        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        });
    }

    // Get cart update details
    const handleCartEditDetails = (item_id, order_detail_id) => {
        // e.preventDefault();
        const params = '?pcl_id=' + global.pcl.id + '&item_id=' + item_id + '&order_id=' + orderId || '';
        Api.get(`client/item-properties/${order_detail_id}` + params, (res) => {
            const data = res.data.data

            const bookings_data = data.bookings;
            setBookingsData([...bookings_data]);
            const properties_arr = handleSetPropertiesOrder(data.properties);
            setProperties(properties_arr);

        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        })
    }

    const handleGetProperties = (item_id) => {
        let qryParams = `?pcl_id=${global.pcl.id}&item_id=${item_id}`;

        Api.get(`client/item-properties` + qryParams, (res) => {
            const data = res.data.data
            if (data.length > 0) {
                setProperties(data)
            } else {
                handleAddToCart(undefined, item_id)
            }
        }, (errors) => {
            // dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })
    }

    // Check is valid bookings
    const isValidBooking = (booking) => {
        let error = null;
        const booking_start_date = moment().add(bookingDetails.advance_booking_days || 0, "days");
        if (!booking.booking_date || booking.booking_date.trim() === '' || moment(booking.booking_date).isBefore(booking_start_date, 'day')) {
            error = 'Select valid booking date';
        }

        error = (error || !booking.bookings || booking.bookings.length === 0);
        for (const subBooking of booking.bookings) {
            if (!subBooking.bookable_service_id) {
                error = 'Bookable service ID is missing';
            }
            if (!subBooking.from_time) {
                error = 'From time is missing';
            }
            if (!subBooking.to_time) {
                error = 'To time is missing';
            }
        }

        return (!error);
    }

    // Count valid bookings count
    const validateBookingsCount = (bookings_arr) => {
        let validCount = 0;
        for (const booking of bookings_arr) {
            let error = null;
            const booking_start_date = moment().add(bookingDetails.advance_booking_days || 0, "days");
            if (!booking.booking_date || booking.booking_date.trim() === '' || moment(booking.booking_date).isBefore(booking_start_date, 'day')) {
                error = 'Add valid booking date';
            }

            for (const subBooking of booking.bookings) {
                if (!subBooking.bookable_service_id) {
                    error = 'Bookable service ID is missing';
                }
                if (!subBooking.from_time) {
                    error = 'From time is missing';
                }
                if (!subBooking.to_time) {
                    error = 'To time is missing';
                }
            }

            if (!error) validCount++;
        }

        return validCount;
    }

    const handleImageChange = (e, index) => {
        properties[index]['image_url'] = URL.createObjectURL(e.target.files[0])
        properties[index]['value'] = e.target.files[0]
        setProperties([...properties])
    }

    const handleSetPropertiesOrder = (properties_arr) => {
        // Sort in ascending order by sort_id and id
        properties_arr.sort((a, b) => {
            // If sort_order is equal, sort by id
            if (a.sort_order === b.sort_order) {
                return a.id - b.id;
            }
            // Otherwise, sort by sort_order
            return a.sort_order - b.sort_order;
        });
        return properties_arr
    }

    async function handleCancelBookings(booking_id) {
        let apiUrl = Api.getApiURL();
        const params = {pcl_id: global.pcl.id}
        if (!booking_id) params['order_detail_id'] = orderDetailId;

        // Request to cancel bookings
        try {
            const res = await axios.delete(apiUrl + `client/bookings${booking_id ? '/' + booking_id : ''}`, {data: params});
            const data = res.data.data;
            if (data.errors) {
                dispatch({type: 'SHOW-SUCCESS', payload: Services.getErrorList(data.errors)})
            }else {
                dispatch({type: 'SHOW-SUCCESS', payload: 'Booking cancelled successfully.'})
            }
            return true;
        } catch (res) {
            console.error('Booking error:', res.response);
            dispatch({type: 'SHOW-ERROR', payload: Services.getErrorList(res.response.data?.data)})
            return false;
        }
    }

    const handleResetBooking = () => {
        let bookings_copy = cloneDeep(bookings)
        const new_booking = bookings_copy[0] ? cloneDeep(bookings_copy[0]) : {};

        bookings_copy.map((booking, index) => {
            if (!booking.id) {
                bookings_copy.splice(index, 1);
            }
        });

        if (Object.entries(new_booking).length > 0) {
            new_booking['booking_date'] = '';
            const b_bookings = new_booking.bookings
            b_bookings.map((b_booking, b_index) => {
                delete b_bookings[b_index].booking_id;
                delete b_bookings[b_index].booking_date;
                b_bookings[b_index]['booking_id'] = '';
                b_bookings[b_index]['booking_date'] = '';
                b_bookings[b_index]['from_time'] = '';
                b_bookings[b_index]['to_time'] = '';
                b_bookings[b_index]['bookable_service_id'] = '';
            });

            delete new_booking.id;
            new_booking['selected'] = true
            new_booking['bookings'] = b_bookings
        }

        bookings_copy = [new_booking]
        if (bookings_copy.length > 0) {
            handleCancelBookings(null).then(res => {
                if (res) {
                    setBookings([...bookings_copy])
                    dispatch({type: 'SHOW-SUCCESS', payload: 'All previous bookings are cancelled successfully.'})
                } else {
                    dispatch({type: 'SHOW-ERROR', payload: 'Sorry, booking not cancel please try again.'})
                }
                return res;
            })
        } else {
            setBookings([...bookings_copy])
            dispatch({type: 'SHOW-SUCCESS', payload: 'All previous bookings are cancelled successfully.'})
            return true;
        }
    }

    // handle property change
    async function handleChange(e, index, value, change_type = null) {
        // if (e) e.preventDefault();

        let update_properties = false;
        let property = cloneDeep(properties[index])
        const is_booking_set = bookings.find(obj => obj.id || (obj.booking_date && obj.booking_date !== ''))
        const is_special_property_change = (property.slug === 'from_date' || property.slug === 'max-bookings' || property.slug === 'bookings-availability');

        if (is_booking_set && is_special_property_change) {
            const result = await Swal.fire({
                title: 'Be Aware!',
                html: `<span>All bookings will be cancelled. Are you sure to change ${property.name}?</span>`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#b35322',
                confirmButtonText: 'Yes Proceed'
            });

            if (!result.isConfirmed) {
                return false;
            } else {
                const res = handleResetBooking();
                update_properties = true;
            }
        }

        properties[index]['value'] = value;
        // check and set max-bookings
        if (property.slug === 'max-bookings') {
            handlePropertyMaxBookings(properties[index]);
        } else if (property.slug === 'from_date') {
            const from_date = (value) ? value : moment().format('YYYY-MM-DD');
            bookingDetails['from_date'] = from_date
            setBookingDetails({...bookingDetails})
            // handleResetBooking();
        }
        else if (property.slug === 'bookings-availability') {
            // handleResetBooking();
            let error = false;
            let avail_weeks = null;
            if (value && property?.property_values) {
                const p_value = property.property_values.find(obj => obj.id == value)
                if (p_value) {
                    let avail_data = p_value.value
                    avail_data = avail_data.split(',');

                    if (avail_data.length === 2 || avail_data.length === 4) {
                        avail_weeks = avail_data.map((week) => week.trim());
                        avail_weeks.map((week, index) => {
                            error = false
                            if (index == 2 || index == 3) {
                                week = avail_weeks[0] + ' ' + week
                            }

                            const parsedDateTime = moment(week, undefined, true);
                            const valid = parsedDateTime.isValid();
                            if (!valid) {
                                error = true;
                            }
                        })

                        if (!error) {
                            if (avail_data[0] && avail_data[1]) {
                                bookingDetails['from_date'] = avail_data[0]
                                bookingDetails['to_date'] = avail_data[1]
                            }

                            if (avail_data[2] && avail_data[3]) {
                                bookingDetails['from_time'] = avail_data[2]
                                bookingDetails['to_time'] = avail_data[3]
                            }

                            // console.log(bookingDetails)
                            setBookingDetails({...bookingDetails})
                        }
                    }
                }
            }
        }


        setSelectedDate(null)
        setProperties([...properties]);
        setPropertyChange(true);

        if (order_id && update_properties && is_special_property_change) {
            handleUpdateCartItemProperties(null)
        }
    }

    // handle property max bookings
    const handlePropertyMaxBookings = (property = null) => {

        // check camp properties if exist
        if (!property) {
            property = properties.find(obj => obj.slug === 'max-bookings')
        }

        let max_bookings = null;
        // check and validate max_bookings value
        if (property) {
            if (property.value && property.property_values) {
                const found = property.property_values.find(obj => obj.id == property.value)
                if (found) {
                    max_bookings = found.value
                    bookingDetails['max_bookings'] = max_bookings
                    setBookingDetails({...bookingDetails})
                } else {
                    dispatch({type: 'SHOW-ERROR', payload: 'Please select correct camp days.'})
                    return false;
                }
            } else {
                dispatch({type: 'SHOW-ERROR', payload: 'Please select correct camp days.'})
                return false;
            }
        }

        return max_bookings;
    }

    // Handle item add to cart
    const handleAddToCart = (e, item_id) => {
        if (e) e.preventDefault();

        const payload = {
            pcl_id: global.pcl.id,
            item_id: item_id || itemId,
            category_id: categoryId || '',
            order_id: global.order_id || '',
            quantity: quantity,
        }

        const properties_clone = cloneDeep([...properties])
        properties_clone.map((property, index) => {
            if (property.property_type == 'select') {
                const f_property = property.property_values.find(obj => obj.value == property.value || obj.id == property.value || obj.name == property.value)
                properties_clone[index].value = (f_property) ? f_property.id : '';
            }

        })

        // console.log('Quantity', quantity)
        // return false;

        payload['properties'] = properties_clone
        if (e) {
            setProperties([...properties_clone])
        } else {
            payload['properties'] = []
        }

        const data = new FormData()
        data.append('pcl_id', global.pcl.id)
        data.append('customer_id', payload['customer_id'] ?? global.user.id ?? '')
        data.append('order_id', payload['order_id'])
        data.append('item_id', item_id || itemId)
        data.append('category_id', categoryId || '')
        data.append('quantity', payload['quantity'])
        data.append('request_type', 'item-add-to-cart')


        let is_booking = false;
        const is_booking_item = properties.find(obj => obj.property_type === 'bookable-services');
        const bookings_data = [];
        const bookings_arr = cloneDeep(bookings);
        bookings_arr.map((booking, index) => {
            // remove slots to avoid sending in post request
            delete booking.slots
            const b_admissions = booking.admissions
            if (isValidBooking(booking)) {
                is_booking = true;
                const admissions_data = []
                b_admissions.map(admission => {
                    const param_adm = {
                        admission_id: admission.id,
                        allowed_person: admission.allowed_person
                    }

                    if (admission.requested_persons != undefined && admission.requested_persons >= 0) {
                        param_adm['requested_persons'] = admission.requested_persons
                    } else if (admission.additional_persons) {
                        param_adm['additional_persons'] = admission.additional_persons
                    }

                    admissions_data.push(param_adm)
                });

                booking['status'] = 2;
                booking['admissions'] = admissions_data;
                bookings_data.push(booking)
            }
        });

        const b_count = validateBookingsCount(bookings_data)
        if (is_booking_item && bookingDetails.max_bookings_required && b_count < bookingDetails.max_bookings) {
            dispatch({
                type: "SHOW-ERROR",
                payload: 'You must need to add ' + bookingDetails.max_bookings + ' bookings to proceed.'
            })
            return false;
        }

        if (is_booking_item && b_count == 0) {
            dispatch({type: "SHOW-ERROR", payload: 'At least one booking is required.'});
            return false;
        }

        data.append('bookings', JSON.stringify(bookings_data))
        payload['properties'].map((property, index) => {
            if (property.property_type == 'image') {
                const img_name = `image_${index}`
                data.append(img_name, property.value)
            }
        })


        data.append('properties', JSON.stringify(payload['properties']))
        Api.post(`client/order-details`, data, (res) => {
            const data = res.data.data;
            dispatch({type: "ORDER-ID", payload: data.order_id})
            dispatch({type: 'ORDER-CUSTOMER', payload: data.customer_id ?? 0});

            const c_time = moment();
            dispatch({type: "ORDER-TIME", payload: moment(c_time).format('YYYY-MM-DD HH:mm')})

            if (is_booking) {
                let new_time = c_time.add(20, 'minutes');
                new_time = moment(new_time).format('YYYY-MM-DD HH:mm')
                dispatch({type: 'CHECKOUT-TIME', payload: new_time})
            } else {
                dispatch({type: 'CHECKOUT-TIME', payload: ''})
            }

            const cart_items_count = Number(global.cart_items_count) + 1;
            dispatch({type: 'CART-ITEMS-COUNT', 'payload': cart_items_count})
            dispatch({type: "SHOW-SUCCESS", payload: 'Item added into cart successfully.'})

            if (data.campaign_item && Object.keys(data.campaign_item).length > 0) {
                handleCampaignItem(data.order_id, data.campaign_item, cart_items_count);
            } else {
                window.location.assign('/cart')
            }

        }, (errors) => {
            setErrors(errors)
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
            //dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        })
    }

    // Campaign Items
    const handleCampaignItem = (order_id, campaign_item, cart_items_count) => {
        if (Object.keys(campaign_item).length > 0 && campaign_item['item_quantity'] > 0) {
            let discount_type = (campaign_item['camp_discount_type'] == 'exact_discount') ? '$' : '%'

            const data = {
                pcl_id: global.pcl.id,
                order_id: global.order_id,
                item_id: campaign_item['camp_item_id'],
                category_id: campaign_item['camp_category_id'],
                price_id: campaign_item['price_id'],
                price_detail_id: campaign_item['price_detail_id'],
                quantity: campaign_item['item_quantity'],
                request_type: 'item-add-to-cart',
            }

            Swal.fire({
                toast: true,
                title: 'Congrats!',
                html: "<div>" +
                    "You have an extra discounted item with this item purchase." + "<br>" +
                    "<span class='text-success'>Item Name: " + campaign_item['item_title'] + " </span><br>" +
                    "<span class='text-danger'>Discount: " + campaign_item['camp_discount'] + discount_type + " </span><br>" +
                    "<span class='text-warning'>Item Price: $" + campaign_item['camp_item_regular_price'] + " </span><br>" +
                    "<span class='text-info'>Discount Quantity: " + campaign_item['item_quantity'] + " </span><br>" +
                    "</div>",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1daf52',
                cancelButtonColor: '#b49311',
                confirmButtonText: 'Yes, Add Item',
                width: '600px'
            }).then((result) => {
                if (result.isConfirmed) {
                    Api.post(`client/order-details`, data, (res) => {
                        const data = res.data.data
                        dispatch({type: 'CART-ITEMS-COUNT', 'payload': Number(cart_items_count) + 1})
                        dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
                        window.location.assign('/cart')
                    }, (errors) => {
                        dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
                    })
                } else {
                    window.location.assign('/cart')
                }
            })

        }
    }


    const handleUpdateCartItemProperties = (e) => {
        if (e) e.preventDefault();

        properties['pcl_id'] = global.pcl.id
        properties['order_id'] = global.order_id
        setProperties(properties)

        let request_data = {};
        request_data['pcl_id'] = global.pcl.id
        request_data['customer_id'] = (global.user && global.user.id) ? global.user.id : ''
        request_data['order_id'] = order_id || global.order_id
        request_data['item_id'] = itemId
        request_data['properties'] = properties

        const data = new FormData()
        data.append('pcl_id', global.pcl.id)
        data.append('customer_id', request_data['customer_id'])
        data.append('order_id', request_data['order_id'])
        data.append('item_id', itemId)
        data.append('_method', 'put')

        request_data['properties'].map((property, index) => {
            if (property.property_type == 'image') {
                const img_name = `image_${index}`
                data.append(img_name, property.value)
            }
        })

        const req_properties = request_data['properties'].filter(prop => prop.property_type !== 'bookable-services')

        const properties_clone = cloneDeep([...req_properties])
        properties_clone.map((property, index) => {
            if (property.property_type === 'select') {
                const f_property = property.property_values.find(obj => obj.value == property.value || obj.id == property.value || obj.name == property.value)
                properties_clone[index].value = (f_property) ? f_property.id : null;
            }

        })

        request_data['properties'] = properties_clone
        data.append('properties', JSON.stringify(request_data['properties']))

        const b_count = validateBookingsCount(bookings)
        if (bookingDetails.max_bookings_required && b_count < bookingDetails.max_bookings) {
            dispatch({
                type: "SHOW-ERROR",
                payload: 'You must need to add ' + bookingDetails.max_bookings + ' bookings to proceed.'
            })
            return false;
        }

        if (b_count == 0) {
            dispatch({type: "SHOW-ERROR", payload: 'At least one booking is required.'});
            return false;
        }

        // if (!orderDetailId) return false;
        Api.post(`client/order-details/${orderDetailId}`, data, (res) => {
            const data = res.data.data
            if (!order_id) {
                window.location.assign('/cart');
                dispatch({type: "SHOW-SUCCESS", payload: res.data.message});
            }
        }, (errors) => {
            //handleErrors(errors, "Error: " + JSON.stringify(errors))
            setErrors(errors)
            dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        })
    }

    const handleContinueButton = (e) => {
        e.preventDefault();

        const b_count = validateBookingsCount(bookings)
        if (bookingDetails.max_bookings_required && b_count < bookingDetails.max_bookings) {
            dispatch({
                type: "SHOW-ERROR",
                payload: 'You must need to add ' + bookingDetails.max_bookings + ' bookings to proceed.'
            })
            return false;
        }

        if (b_count == 0) {
            dispatch({type: "SHOW-ERROR", payload: 'At least one booking is required.'});
            return false;
        }

        window.location.assign('/cart')
    }

    const handleBookingItemChange = (val) => {
        if (orderDetailId != val.value) {
            setBookings([])
            setItemId(val.item_id)
            setProperties([])
            setOerDetailId(val.value)
            setSelectedBookingItem(val)
            setBookingDetails({})
        }
    }

    const dependency_check = (property) => {
        let dependency = true;
        if (property.depends_on_property) {
            dependency = false;
            const parentProp = properties.find(p => p.id === property.depends_on_property.depends_on_property_id);

            if (parentProp && property.depends_on_property.depends_on_property_value == parentProp.value) {
                dependency = dependency_check(parentProp); //Nested call
            }
        }

        return dependency;
    }

    const show_property = (property) => {
        if (!property) return null; // Guard clause for invalid property

        const pType = property.property_type
        const index = properties.findIndex(obj => obj.id === property.id);
        const dependent_properties = properties.filter(p => p.depends_on_property?.depends_on_property_id == property.id && p.depends_on_property.depends_on_property_value == property.value);

        let bookable_service = false;
        const booking_edit = bookings.find(b => b?.selected == true)
        const booking_property = properties.find(obj => obj.property_type === 'bookable-services')
        if (pType === 'bookable-services') {
            isBookingDisplayed++;
            bookable_service = true;
        }

        let col = (bookable_service) ? 12 : property.property_size;
        if (property.slug === 'from_date' && property.value === 'TODAY') {
            handleChange(undefined, index, moment().format('YYYY-MM-DD'))
        }

        if (property.slug === 'page-break') return null;
        col = (pType === 'line-break' || pType === 'section-break') ? 12 : col
        const req_label_class = (property.required) ? 'req-label' : '';
        const isSpecialBookingProperty = (property.slug === 'from_date' || property.slug === 'max-bookings' || property.slug === 'bookings-availability');

        let property_value = '-';
        if (pType === 'select') {
            const f_property = property.property_values.find(obj => obj.value == property.value || obj.id == property.value || obj.name == property.value)
            if (f_property) property_value = f_property.id
        } else if (pType !== 'bookable-services') {
            property_value = property.value;
        }
        if (!orderDetailId && !property.value) {
            //load property default value
            property.value = property.property_values.find(item => item.is_default)?.id ?? null;
        }

        // Render the property
        return (
            <>
                <Col key={property.id} md={col} className={`col-md-${col} mb-2`}>

                    {/* Bookings section start */}
                    {(bookable_service && isBookingDisplayed === 1 && itemId) &&
                        <>
                            <Bookings itemId={itemId} orderId={order_id || orderId} orderDetailId={orderDetailId} item={item} bookingDetails={bookingDetails}
                                      bookings={bookings} setBookings={setBookings} properties={properties} setProperties={setProperties}
                                      bookingsData={bookingsData} selectedDate={selectedDate} setSelectedDate={setSelectedDate}
                                      setPropertyChange={setPropertyChange} handleCancelBookings={handleCancelBookings}/>
                        </>
                    }
                    {/* End of bookings section */}

                    {/* Normal properties section */}
                    {((!order_id && !booking_edit && (isBookingSelected === -1 || !booking_property)) || (isSpecialBookingProperty || property.sort_order < booking_property.sort_order)) &&
                        <>
                            {(pType === 'section-break') &&
                                <div className='property-section-break'>{property.name}</div>
                            }

                            {(pType === 'text' || pType === 'number' || pType === 'time') &&
                                <Form.Group className="mb-3 custom-input-style" controlId="name">
                                    <Form.Label className={`${req_label_class}`}>{property.name}</Form.Label>
                                    <Form.Control size='md' type={pType}
                                                  className={(errors[property.slug]) && 'is-invalid'}
                                                  value={(pType == 'text' || pType == 'number') ? property.value : null}
                                                  onChange={(e) => handleChange(e, index, e.target.value)}
                                    />
                                    <Form.Text className="text-muted fs-11x">{property.description}</Form.Text>
                                    <div className="invalid-feedback">{errors[property.slug]}</div>
                                </Form.Group>
                            }

                            {(pType === 'date') &&
                                <Form.Group className="mb-3 custom-input-style" controlId="name">
                                    <Form.Label className={`${req_label_class}`}>{property.name}</Form.Label>
                                    <Form.Control size='md' type={pType}
                                                  className={(errors[property.slug]) && 'is-invalid'}
                                                  value={moment(property.value).format('YYYY-MM-DD')}
                                                  onChange={(e) => handleChange(e, index, e.target.value)}
                                    />
                                    <Form.Text className="text-muted fs-11x">{property.description}</Form.Text>
                                    <div className="invalid-feedback">{errors[property.slug]}</div>
                                </Form.Group>
                            }

                            {(pType === 'select' && property.property_values.length > 0) &&
                                <Form.Group className="mb-3 custom-input-style" controlId="name_2">
                                    <Form.Label className={`${req_label_class} `}>{property.name}</Form.Label>
                                    <Form.Select size='md' value={property_value}
                                                 className={(errors[property.slug]) && 'is-invalid'}
                                                 onChange={(e) => handleChange(e, index, e.target.value, 'select')}
                                    >

                                        <option value=''>---</option>
                                        {property.property_values.map((value) => (
                                            <option key={`s-${value.id}`} value={value.id}>
                                                {(value.quantity > 1 ? (value.quantity + ' ') : '') + value.name}
                                                {(value.price != null && value.price !== 0) &&
                                                    <>&nbsp;({`$${value.price * value.quantity} ${(value.price >= 0) ? 'extra' : 'discount'}`})</>
                                                }
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Text className="text-muted fs-11x">{property.description}</Form.Text>
                                    <div className="invalid-feedback">{errors[property.slug]}</div>
                                </Form.Group>
                            }

                            {(pType === 'radio' && property.property_values.length > 0) &&
                                <div style={{marginTop: '37px'}}>
                                    {property.property_values.map((option) => (
                                        <Form.Check key={`inline-${option.id}`} inline name="radio1"
                                                    className={(errors[property.slug]) && 'is-invalid'}
                                                    label={`${option.name} ($${option.price})`}
                                                    value={option.id}
                                                    type='radio' id={`inline-${option.id}`}
                                                    onChange={(e) => handleChange(e, index, e.target.value)}
                                        />
                                    ))}
                                    <Form.Text
                                        className="text-muted fs-11x">{property.description}</Form.Text>
                                    <div className="invalid-feedback">{errors[property.slug]}</div>
                                </div>
                            }

                            {(pType === 'checkbox') &&
                                <div style={{marginTop: '37px'}}>
                                    <Form.Group className="mb-3 custom-input-style checkbox-lg" controlId="checkbox">
                                        <Form.Check type="checkbox" size='ld' label={property.name}
                                                    className={(errors[property.slug]) && 'is-invalid'}
                                                    defaultChecked={!!(property.value)}
                                                    onChange={(e) => handleChange(e, index, e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Text className="text-muted fs-11x">{property.description}</Form.Text>
                                    <div className="invalid-feedback">{errors[property.slug]}</div>
                                </div>
                            }

                            {(pType === 'textarea') &&
                                <Form.Group className="mb-3 custom-input-style" controlId="description">
                                    <Form.Label className={`${req_label_class}`}>{property.name}</Form.Label>
                                    <Form.Control size='md' as="textarea" placeholder={property.name}
                                                  rows={2}
                                                  className={(errors[property.slug]) && 'is-invalid'}
                                                  value={property.value}
                                                  onChange={(e) => handleChange(e, index, e.target.value)}
                                    />
                                    <Form.Text className="text-muted fs-11x">{property.description}</Form.Text>
                                    <div className="invalid-feedback">{errors[property.slug]}</div>
                                </Form.Group>
                            }

                            {(pType === 'image') &&
                                <div>
                                    <Form.Group className="mb-3 custom-input-style" controlId="image">
                                        <Form.Label className={`${req_label_class}`}>{property.name}</Form.Label>
                                        <Form.Control type="file" size='md' placeholder="select image"
                                                      className={(errors[property.slug]) && 'is-invalid'}
                                                      onChange={(e) => handleImageChange(e, index)}
                                        />
                                        <Form.Text className="text-muted fs-11x">{property.description}</Form.Text>
                                        <div className="invalid-feedback">{errors[property.slug]}</div>
                                    </Form.Group>

                                    {property.image_url &&
                                        <img src={property.image_url} alt="preview" style={{width: '70px'}}/>
                                    }
                                </div>
                            }
                        </>
                    }
                </Col>

                {/* Recursively show dependent properties */}
                {dependent_properties.map((dependentProp, idx) => (
                    <React.Fragment key={dependentProp.id}>
                        {show_property(dependentProp)}
                    </React.Fragment>
                ))}
            </>
        );
    }

    let isBookingDisplayed = 0;
    let isBookingSelected = bookings.findIndex(obj => obj.selected === true);

    const from_date_pro = properties.find(obj => obj.slug === 'from_date')
    const max_bookings_pro = properties.find(obj => obj.slug === 'max-bookings')
    const admissions_avail_pro = properties.find(obj => obj.slug === 'bookings-availability')
    const isBookingEditable = ((from_date_pro && !from_date_pro.value) || (max_bookings_pro && !max_bookings_pro.value) || (admissions_avail_pro && !admissions_avail_pro.value)) ? false : true;

    const targetHeight = 32
    const selectStyles = {
        control: (base) => ({
            ...base,
            minHeight: 'initial',
        }),
        valueContainer: (base) => ({
            ...base,
            height: `${targetHeight - 1 - 1}px`,
            padding: '0 8px',
        }),
        clearIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
    }

    console.log('bookingDetails: ', bookingDetails)
    // console.log('bookingDetails: ', bookingDetails)
    return (
        <div className=''>
            <Container className='bg-white min-vh-100'>

                {/* Cart title */}
                <div className='d-flex align-items-center gap-2 flex-wrap bb-1px'>
                    <div className='fw-bold fs-22x text-light-green'>{item.name}</div>
                    {(item.description_short) &&
                        <div className='fs-16x'>({item.description_short})</div>
                    }
                </div>

                {(order_id) &&
                    <div className='d-flex justify-content-center mb-3'>
                        <div style={{maxWidth: '460px'}}>
                            <label className='mb-2 mt-3 fw-bold fs-20x'>Select booking item to manage bookings</label>
                            <Select styles={selectStyles} options={bookingItems} value={selectedBookingItem}
                                    onChange={(value) => handleBookingItemChange(value)}
                                    placeholder="Select Item"
                            />
                        </div>
                    </div>
                }

                {/* Max bookings note */}
                {(bookingDetails.max_bookings > 1 || bookingDetails.max_bookings_per_day > 1) &&
                    <div className='booking-note mt-2 mb-1'>
                        <span className='fw-bold'>Note: </span>
                        <span>Maximum {bookingDetails.max_bookings} bookings allowed,</span>&ensp;
                        <span>Maximum {bookingDetails.max_bookings_per_day} bookings/day allowed</span>
                    </div>
                }

                <div>
                    <Row className='mt-4 justify-content-center'>
                        {/* Item properties */}
                        {properties.map((property, index) => {
                            if (property.depends_on_property && property.depends_on_property.id) {
                                return null;
                            }

                            // Show normal property
                            return (
                                <>{show_property(property)}</>
                            )
                        })}
                    </Row>

                    {/* Show errors */}
                    <div>
                        {(errorsArr.length > 0) &&
                            <div className="alert alert-danger p-8-10x" role="alert">
                                {errorsArr.map((error, index) => {
                                    return (
                                        <div key={index} className='text-danger'><strong>{`${index + 1}. `}</strong>
                                            {error}</div>
                                    )
                                })}
                            </div>
                        }
                    </div>

                    <div className='text-end mt-3 border-top p-2'>
                        {(!order_id)&&
                        <>
                            {(((bookings.length >= 1 && !bookings.find(b => b.selected === true) && validateBookingsCount(bookings) >= 1) && (propertyChange)) || (bookings.length === 0 && propertyChange)) &&
                                <>
                                    <Button variant="primary" size='md' className='me-1' onClick={(e) =>
                                        (orderDetailId) ? handleUpdateCartItemProperties(e) : handleAddToCart(e, itemId)}>
                                        {(orderDetailId) ? 'Save Changes' : 'Save Item '}
                                    </Button>
                                </>
                            }

                            {(!propertyChange && !bookings.find(b => b.selected === true) && orderDetailId) &&
                                <Button type="button" variant="primary" size='md' className='me-1'
                                        onClick={(e) => handleContinueButton(e)}>
                                    Continue
                                </Button>
                            }

                            <a href={(orderDetailId) ? '/cart' : '/pos'} className='btn btn-secondary me-1'>Cancel</a>
                        </>
                        }

                        {(order_id) &&
                            <a href={`/my-orders`} className='btn btn-secondary me-1'>Close</a>
                        }
                    </div>
                </div>

            </Container>
        </div>
    )
}