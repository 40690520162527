import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../_services/global-context";
import {Button, Col, Form, Modal, Row, Table, Card} from "react-bootstrap";
import Swal from "sweetalert2";
import Api from "../_services/api_service";
import Select from "react-select";
import {
    Envelope,
    Pencil,
    Plus,
    Repeat,
    Trash3,
    Clock,
    CheckCircle,
    XCircle,
    DashCircle,
    ArrowClockwise
} from "react-bootstrap-icons";
import URL from "../_services/routes";
import moment from "moment";
import Services from "../_services/services";

export default function Rsvp() {
    const [global, dispatch] = useContext(GlobalContext);
    const [rsvps, setRsvps] = useState([]);
    const [itemId, setItemId] = useState('');
    const [rsvpEdit, setRsvpEdit] = useState(false);
    const [errors, setErrors] = useState({});
    const [rsvpId, setRsvpId] = useState(null);
    const [rsvpModal, setRsvpModal] = useState(false);

    const [booking, setBooking] = useState({});
    const [bookings, setBookings] = useState([]);
    const [selectBookings, setSelectBookings] = useState([]);
    const [selectedBooking, setSelectedBooking] = useState({});

    const [admissions, setAdmissions] = useState([]);
    const [selectedAdmission, setSelectedAdmission] = useState({});
    const [admissionOptions, setAdmissionOptions] = useState([]);

    const rsvp_default = {email: '', contact_no: '', description: '', status: 1}
    const rsvp_member_default = {first_name: '', last_name: '', admission_id: '', status: 1}
    const [rsvp, setRsvp] = useState(rsvp_default);
    const [rsvpMembers, setRsvpMembers] = useState([rsvp_member_default]);

    const default_summery = {allowed: 0, invited: 0, attending: 0, not_attending: 0, waiting: 0}
    const [rsvpSummery, setRsvpSummery] = useState(default_summery);


    const order_id = URL.urlQueryParams('order-id')
    const [orderId, setOrderId] = useState(order_id || 0);

    // Effects
    useEffect(() => {
        handleLoadBookings();
    }, [])

    useEffect(() => {
        if (selectedBooking.value) {
            handleLoadRsvps();

            const booking = bookings.find(obj => obj.id == selectedBooking.value);
            if (booking && booking.item_id) {
                handleLoadAdmissions(booking.item_id);
            }
        }

    }, [selectedBooking])

    useEffect(() => {
        const rsvps_summery = default_summery
        rsvps.map(rsvp => {
            rsvp.members.map((member, memberIndex) => {
                rsvps_summery.invited += 1
                if (member.status == 1) rsvps_summery.waiting += 1;
                else if (member.status == 2) rsvps_summery.attending += 1;
                else if (member.status == 3) rsvps_summery.not_attending += 1;
            });
        })

        setRsvpSummery({...rsvps_summery})
    }, [rsvps])

    // Load Rsvp data
    function handleLoadRsvps() {
        const booking_id = selectedBooking.value || '';
        const qryParams = '?pcl_id=' + global.pcl.id + "&booking_id=" + booking_id + "&request_type=list"
        Api.get(`client/rsvps` + qryParams, (res) => {
            const data = res.data.data
            setRsvps(data)
        });
    }

    function handleLoadBookings() {
        const qryParams = '?pcl_id=' + global.pcl.id + "&order_id=" + orderId + "&booking_type=list"
        Api.get(`client/bookings` + qryParams, (res) => {
            const data = res.data.data;
            setBookings([...data])

            const options = [];
            data.map((booking, index) => {
                if (!booking.enable_rsvp) return null;
                const booking_date = moment(booking.booking_date).format('DD-MM-YYYY')
                const option = {value: booking.id, label: ' (' + booking_date + ') ' + booking.item_name}
                options.push(option);
            });

            if (!selectedBooking.value) {
                setSelectedBooking({...options[0]});
            }

            setSelectBookings([...options]);
        });
    }

    function handleLoadAdmissions(item_id) {
        const qryParams = '?pcl_id=' + global.pcl.id + "&item_id=" + item_id + ""
        Api.get(`client/item-admissions` + qryParams, (res) => {
            const data = res.data.data
            setAdmissions([...data])

            const options = [];
            data.map(admission => {
                const option = {value: admission.id, label: admission.name}
                options.push(option)
            });

            setAdmissionOptions([...options])
        });
    }

    const handleErrors = (errors, message) => {
        setErrors(errors);
        dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
    }

    const handleSetDefaultValues = () => {
        setRsvp(rsvp_default)
    }

    function handleGetDetails(rsvp_id) {
        const qryParams = '?pcl_id=' + global.pcl.id;
        Api.get(`client/rsvps/${rsvp_id}` + qryParams, (res) => {
            const data = res.data.data
            if (data && data.members) {
                setRsvpMembers(data.members)
                setRsvpEdit(true)
            }
            setRsvp(data);
        });
    }

    const handleChange = (fieldName, value, index = null) => {
        if (index !== null) {
            if (fieldName == 'admission_id') {
                value = value.value;
            }

            rsvpMembers[index][fieldName] = value
            setRsvpMembers([...rsvpMembers])
        } else {
            if (fieldName == 'booking_id') {
                setSelectedBooking(value)
                value = value.value;
            }
            rsvp[fieldName] = value
            setRsvp({...rsvp})
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        rsvp['pcl_id'] = global.pcl.id;
        rsvp['booking_id'] = selectedBooking.value || ''
        rsvp['members'] = rsvpMembers;

        Api.post(`client/rsvps`, rsvp,
            (res) => {
                setRsvpEdit(false);
                setRsvp(rsvp_default)
                setRsvps(res.data.data)
                dispatch({type: "SHOW-SUCCESS", payload: "Item rsvp added successfully"})
            },
            (errors) => {
                handleErrors(errors, "Error: " + JSON.stringify(errors))
            });
    }

    const handleEditSubmit = (e) => {
        e.preventDefault()
        rsvp['pcl_id'] = global.pcl.id
        rsvp['booking_id'] = selectedBooking.value || ''
        rsvp['members'] = rsvpMembers;

        Api.put(`client/rsvps/${rsvp['id']}`, rsvp,
            (res) => {
                setRsvps(res.data.data)
                setRsvp(rsvp_default)
                setRsvpEdit(false)
                dispatch({type: "SHOW-SUCCESS", payload: "Rsvp updated successfully"})
            },
            (errors) => {
                handleErrors(errors, "Error: " + JSON.stringify(errors))
            });
    }

    const handleDelete = (rsvp_id) => {

        const params = {
            pcl_id: global.pcl.id,
            booking_id: selectedBooking.value || ''
        }

        Swal.fire({
            title: '',
            text: "Are to sure to delete rsvp?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#b35322',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.delete(`client/rsvps/${rsvp_id}`, {data: params}, (res) => {
                    const data = res.data.data
                    setRsvps(data)
                    dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
                }, (errors) => {
                    dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
                })
            }
        })

    }

    const handleAddMember = (index) => {
        rsvpMembers.push(rsvp_member_default)
        setRsvpMembers([...rsvpMembers])
    }

    const handleRemoveMember = (index, member_id) => {
        if (member_id) {
            rsvpMembers[index]['status'] = 5
        } else {
            rsvpMembers.splice(index, 1)
        }

        setRsvpMembers([...rsvpMembers])
    }

    const handleInvitationEmail = (rsvp_id, email_type = 'waiver-reminder') => {
        Swal.fire({
            title: '',
            text: `Are you sure you want to send the ${email_type.replace('-', ' ')} email?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#b35322',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                handleSendInvitationMail(rsvp_id, email_type)
            }
        })
    }

    const handleSendInvitationMail = (rsvp_id, email_type = 'waiver-reminder') => {
        const mail = {'pcl_id': global.pcl.id, email_type: email_type}
        Api.post(`client/rsvps/${rsvp_id}/email`, mail, (res) => {
            // const data = res.data.data
            handleLoadRsvps()

            const message = (email_type === 'waiver-reminder') ? 'Waiver reminder email sent successfully.' : 'Invitation email sent successfully.';
            dispatch({type: "SHOW-SUCCESS", payload: message})
        }, (errors) => {
            // dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
            dispatch({type: 'SHOW-ERROR', payload: 'Failed to send email. Please make sure all information are provided'});
        });
    }

    const targetHeight = 31
    const selectStyles = {
        control: (base) => ({
            ...base,
            minHeight: 'initial',
        }),
        valueContainer: (base) => ({
            ...base,
            height: `${targetHeight - 1 - 1}px`,
            padding: '0 8px',
        }),
        clearIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
    }

    // console.log(bookingsList)
    return (
        <div className=''>
            <div className="py-4">
                {/* Heading */}
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2 className="text-primary">Manage RSVP List</h2>
                </div>

                {/* Description */}
                <p className="text-muted">
                    Manage the RSVP list for your event. Use this page to add, edit, delete, or send invitations. You
                    can track the
                    status of invitations, and you'll receive an alert email when someone responds.
                </p>


                {/* Booking Selector */}
                <Row className="align-items-center mb-4">
                    <Col sm="auto">
                        <Form.Label>Select Booking:</Form.Label>
                    </Col>
                    <Col sm="auto" className="mb-2">
                        <Select styles={selectStyles} placeholder="Select a booking"
                                options={selectBookings}
                                value={selectedBooking}
                                onChange={(value) => setSelectedBooking(value)}
                        />
                    </Col>
                </Row>
                {(!rsvpEdit) &&
                    <div>
                        <div className="text-secondary"><span className='fw-bold text-dark-light'>Invitation Sent:</span> {rsvpSummery.invited} Guests</div>
                        <div className="text-secondary"><span className='fw-bold text-dark-light'>Attending:</span> {rsvpSummery.attending} Guests</div>
                        <div className="text-secondary"><span className='fw-bold text-dark-light'>Not Attending:</span> {rsvpSummery.not_attending} Guests</div>
                        <div className="text-secondary"><span className='fw-bold text-dark-light'>Waiting for Response:</span> {rsvpSummery.waiting} Guests</div>
                    </div>
                }


            </div>

            {/* Item rsvps update and create form */}
            {!rsvpEdit &&
                <div className="text-end mt-2 mt-sm-0 mb-4">
                    <Button variant="primary" size='sm' className='me-2' onClick={() => {
                        setRsvpEdit(true);
                        setRsvp({email: "", contact_no: "", custom_message: "", members: []});
                        setRsvpMembers([{first_name: "", last_name: "", admission_id: "", status: 1}]);
                    }}>
                        <Plus/> Add RSVP
                    </Button>

                    <Button variant="secondary" className='me-2' size='sm' onClick={handleLoadRsvps}>
                        <Repeat/><span className="d-none d-md-inline">&nbsp;Refresh List</span>
                    </Button>
                </div>
            }

            {rsvpEdit &&
                <div className='rsvp-form'>
                    <Form autoComplete="off" onSubmit={(rsvp.id) ? handleEditSubmit : handleSubmit}>
                        <div className='rsvp-form-header'>
                            <Row>
                                <Col sm={6}>
                                    <div className='d-flex gap-2 mb-2'>
                                        <Form.Label className='mb-0' style={{width: '154px'}}>Rsvp Invitation Email:</Form.Label>
                                        <div className='flex-1'>
                                            <Form.Control size='sm' type="email"
                                                          className={`${errors.email} && 'is-invalid'`}
                                                          defaultValue={rsvp.email}
                                                          onChange={(e) => handleChange('email', e.target.value)} required/>
                                            <div className="invalid-feedback">{errors.email}</div>
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={6}>
                                    <div className='d-flex gap-2 mb-2'>
                                        <Form.Label className='mb-0' style={{width: '154px'}}>Contact No:</Form.Label>
                                        <div className='flex-1'>
                                            <Form.Control size='sm' type="text"
                                                          className={(errors.contact_no) && 'is-invalid'}
                                                          defaultValue={rsvp.contact_no}
                                                          onChange={(e) => handleChange('contact_no', e.target.value)}
                                                          required/>
                                            <div className="invalid-feedback">{errors.contact_no}</div>
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={12}>
                                    <div className='d-flex gap-2 mb-2'>
                                        <Form.Label className='mb-0' style={{width: '154px'}}>Custom Message:</Form.Label>
                                        <div className='flex-1'>
                                            <Form.Control size='sm' as="textarea" rows={2}
                                                          className={`${errors.custom_message && 'is-invalid'}`}
                                                          defaultValue={rsvp.custom_message}
                                                          onChange={(e) => handleChange('custom_message', e.target.value)}/>
                                            <div className="invalid-feedback">{errors.custom_message}</div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='rsvp-form-members'>
                            <div className='border-bottom mb-2'></div>

                            {rsvpMembers.map((member, index) => {
                                const m_admission = admissionOptions.find(obj => obj.value === member.admission_id);

                                if (member.status !== 5) {
                                    return (
                                        <div key={index} className='d-flex gap-3 flex-wrap rsvp-form-member'>
                                            <div className='flex-1 rsvp-form-member-item'>
                                                <div className='d-flex gap-2'>
                                                    <div className='hide-on-mobile'>
                                                        <span style={{backgroundColor: '#dedede', padding: '3px 8px', borderRadius: '15px', fontSize: '13px'}}>
                                                            {index + 1}
                                                        </span>
                                                    </div>

                                                    <Form.Label className='mb-0' style={{minWidth: '102px'}}>First Name:</Form.Label>
                                                    <div className='flex-1'>
                                                        <Form.Control size='sm' type="text"
                                                                      className={(errors.first_name) && 'is-invalid'}
                                                                      defaultValue={member.first_name}
                                                                      onChange={(e) => handleChange('first_name', e.target.value, index)}
                                                                      required/>
                                                        <div className="invalid-feedback">{errors.first_name}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='flex-1 rsvp-form-member-item'>
                                                <div className='d-flex gap-2'>
                                                    <Form.Label className='mb-0' style={{minWidth: '102px'}}>Last Name:</Form.Label>
                                                    <div className='flex-1'>
                                                        <Form.Control size='sm' type="text"
                                                                      className={(errors.last_name) && 'is-invalid'}
                                                                      defaultValue={member.last_name}
                                                                      onChange={(e) => handleChange('last_name', e.target.value, index)}
                                                                      required/>
                                                        <div className="invalid-feedback">{errors.last_name}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='flex-1 rsvp-form-member-item'>
                                                <div className='d-flex gap-2'>
                                                    <Form.Label className='mb-0'>Select Age Group:</Form.Label>
                                                    <div className='flex-1'>
                                                        <Select className={(errors.admission_id) && 'is-invalid'}
                                                                styles={selectStyles}
                                                                value={m_admission} options={admissionOptions}
                                                                onChange={(e) => handleChange('admission_id', e, index)}
                                                                required/>
                                                        <div className="invalid-feedback">{errors.admission_id}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='flex-1 rsvp-form-member-item d-flex justify-content-end'>
                                                {(index >= 1) &&
                                                    <button type='button' className='btn btn-danger btn-xs me-1'
                                                            onClick={() => handleRemoveMember(index, member.id || null)}>
                                                        <Trash3/>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            })}

                            <div className='text-center'>
                                <button type='button' className='btn btn-success btn-md me-1'
                                        onClick={(e) => handleAddMember(e)}>
                                    <Plus className='fs-17x'/> Guest
                                </button>
                            </div>

                            <div className='text-end mt-3'>
                                <Button size='sm' type="submit" variant="primary"
                                        className='me-2'>{(rsvp.id) ? 'Save Changes' : 'Add Rsvp'}</Button>
                                <Button size='sm' type="button" variant="warning" onClick={() => {
                                    setRsvpMembers([rsvp_member_default])
                                    setRsvp(rsvp_default)
                                    setRsvpEdit(false)
                                }}>Cancel</Button>
                            </div>

                        </div>
                    </Form>
                </div>
            }

            {(!rsvpEdit) &&
                <div className='rsvp-list-section'>
                    {rsvps.map((rsvp, index) => {
                        let status = '';
                        switch (rsvp.status) {
                            case 2:
                                status = 'Invitation sent';
                                break;
                            case 3:
                                status = 'Invitation accepted';
                                break;
                            case 4:
                                status = 'Not accepted';
                                break;
                            default:
                                status = 'Email not sent';
                        }

                        const unsigned_member = rsvp.members.findIndex(m => m.status === 2 && !m.waiver_member_id);

                        return (
                            <div key={rsvp.id} className='rsvp-item'>
                                <div className="d-flex flex-wrap gap-3 rsvp-item-header align-items-center">
                                    <div className='' style={{width: '23px'}}>{index + 1}.</div>
                                    <div className=''>{rsvp.rsvp_id}</div>
                                    <div className='' style={{maxWidth: '237px'}}>{rsvp.email}</div>
                                    <div className='' style={{width: '137px'}}>
                                    <span
                                        className={rsvp.status === 3 ? 'text-success' : rsvp.status === 4 ? 'text-danger' : 'text-muted fw-bold'}>
                                        {status}
                                    </span>
                                    </div>
                                    <div className='flex-1 d-flex gap-2 justify-content-end'>
                                        {(unsigned_member >= 0 && (rsvp.status === 3 || rsvp.status === 4)) &&
                                            <Button type='button' size='sm' variant='outline-success'
                                                    onClick={() => handleInvitationEmail(rsvp.id, 'waiver-reminder')}>
                                                <Envelope className="me-2"/> Waiver Reminder
                                            </Button>
                                        }

                                        <Button type='button' size='sm' variant='outline-primary'
                                                onClick={() => handleInvitationEmail(rsvp.id, 'party-invitation')}>
                                            {(rsvp.status === 1) ?
                                                <><Envelope/> Send Email</>
                                                :
                                                <><ArrowClockwise/> Resend Email</>
                                            }
                                        </Button>

                                        <Button type='button' size='sm' variant='outline-success'
                                                onClick={() => handleGetDetails(rsvp.id)}>
                                            <Pencil/>
                                        </Button>

                                        {(rsvp.status === 1 || rsvp.status === 2)&&
                                            <Button type='button' size='sm' variant='outline-danger'
                                                    onClick={() => handleDelete(rsvp.id)}>
                                                <Trash3/>
                                            </Button>
                                        }
                                    </div>
                                </div>

                                {/* RSVP Members view list */}
                                <div className='ms-1 me-1 pb-3'>
                                    <table className='rsvp-members-table' style={{width: '100%'}}>
                                        <thead className=''>
                                            <tr>
                                                <th className=''>Guest</th>
                                                <th className=''>Group</th>
                                                <th className=''>Response</th>
                                                <th className=''>Waiver</th>
                                            </tr>
                                        </thead>
                                        {rsvp.members.map((member, memberIndex) => {
                                            let rsvp_status = 'Action Needed';
                                            let memberStatusClass = '';
                                            let memberIcon = '';
                                            let waiver_status = !!(member.waiver_id && member.waiver_member_id)

                                            if (rsvp.status === 1) {
                                                memberStatusClass = 'text-dark'; // Black color
                                                memberIcon = <Envelope className="me-1"/>;
                                            } else if (rsvp.status === 2 || rsvp.status === 3) {
                                                if (member.status === 1) {
                                                    rsvp_status = 'Action Needed'
                                                    memberStatusClass = ''; // Yellow
                                                    memberIcon = <Clock className="me-1"/>;
                                                } else if (member.status === 2) {

                                                    rsvp_status = 'Attending'
                                                    memberStatusClass = 'text-success'; // Green
                                                    memberIcon = <CheckCircle className="me-1"/>;
                                                } else {
                                                    rsvp_status = 'Attending';
                                                    memberStatusClass = 'text-danger'; // Red
                                                    memberIcon = <XCircle/>;
                                                }
                                            } else if (rsvp.status === 4) {
                                                status = 4;
                                                rsvp_status = 'Attending';
                                                memberStatusClass = 'text-danger'; // Red
                                                memberIcon = <XCircle className="me-1"/>;
                                            } else {
                                                rsvp_status = '-';
                                                memberStatusClass = 'text-muted'; // Default muted color
                                                memberIcon = <DashCircle className="me-1"/>;
                                            }

                                            return (
                                                <tr key={memberIndex}>
                                                    <td className=''>
                                                        {member.first_name} {member.last_name}
                                                    </td>
                                                    <td>{member.name}</td>
                                                    <td className=''><span
                                                        className={memberStatusClass}>{memberIcon} {rsvp_status}</span>
                                                    </td>
                                                    <td className=''>
                                                        {(waiver_status) ?
                                                            <div className='rsvp-waiver-status'><CheckCircle className='text-success'/><span className='hide-on-mobile ms-1'>Signed</span></div>
                                                            :
                                                            <div className='rsvp-waiver-status'><XCircle className='text-danger'/><span className='hide-on-mobile ms-1'>Not Signed</span></div>
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </table>
                                </div>
                            </div>

                        );
                    })}
                </div>
            }


            {/*  RSVP modal  */}
            <Modal show={rsvpModal} animation={false} size="xl">
                <Modal.Header>
                    <Modal.Title>Manage RSVP</Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close"
                            onClick={() => setRsvpModal(false)}></button>
                </Modal.Header>

                <Modal.Body>
                    {/*    */}
                </Modal.Body>
            </Modal>

        </div>
    )
}
