import React, {useContext, useEffect, useState} from "react";
import URL from "../_services/routes";
import Api from "../_services/api_service";
import {GlobalContext} from "../_services/global-context";
import {Col, Container, Row} from "react-bootstrap";
import pd_image from "../../images/product-dummy-image-1.jpg";
import Services from "../_services/services";
import moment from "moment/moment";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import ItemDetails from "./item";

export default function POS({cart, setCart}) {
    const navigate = useNavigate();
    const [global, dispatch] = useContext(GlobalContext);
    const [categories, setCategories] = useState([]);
    const [selectedRootCategory, setSeletecRootCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [properties, setProperties] = useState([]);
    const [quantity, setQuantity] = useState(null);
    const [campaignItem, setCampaignItem] = useState(null);

    const base_url = URL.redirectUrl();

    const path = URL.urlPath();
    let search_val = URL.urlRequest()
    const base_path_exp = (search_val !== '') ? search_val.split('/') : []

    // Effects
    useEffect(() => {
        if (global.pcl.id) {
            loadTopCategories()
        }
    }, [global.pcl.id])

    const loadTopCategories = () => {
        
        const item = URL.urlQueryParams('item');
        let qryParams = '?level=0&pcl_id=' + global.pcl.id;
        if (item) {
            qryParams += '&item=' + item
        }

        console.log("Loading top categories:", qryParams);
        Api.get(`client/categories` + qryParams, (res) => {
            const data = res.data
            setCategories(data.categories)
            if (search_val == '') {
                search_val = data.categories[0].slug;
            }
            loadSelectedCategory(search_val);
        }, (errors) => {
            // dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        })
    }

    const loadSelectedCategory = (category_path) => {
        const item = URL.urlQueryParams('item');
        let qryParams = '?level=2&pcl_id=' + global.pcl.id;
        if (item) {
            qryParams += '&item='+item
        }
        console.log("Loading selected categories:", qryParams);
        qryParams += '&category=' + category_path;

        category_path = category_path.split('/');
        
        if (category_path.length) {
            setSeletecRootCategory(category_path[0]);
        }

        Api.get(`client/categories` + qryParams, (res) => {
            setSelectedCategory(res.data)
            if (category_path.length == 1) {
                navigate('/pos/' + category_path[0]);
            }
        }, (errors) => {
            // dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        })
    }

    const handleCheckProperties = (category_id, item) => {
        const item_id = item.id;
        const min_qty_per_order = item.min_qty_per_order;
        const qty = quantity || min_qty_per_order || 1;
        if (item.property_count) {
            window.location.assign(`/cart/add?category_id=${category_id}&item_id=${item_id}&quantity=${qty}`);
        }else {
            handleAddToCart(category_id, item_id, qty)
        }
    }

    const handleAddToCart = (category_id, item_id, i_quantity) => {
        const item = '';

        const payload = {
            pcl_id: global.pcl.id,
            item_id: item_id,
            category_id: category_id,
            order_id: global.order_id || '',
            quantity: i_quantity
        }

        Api.post(`client/order-details`, payload, (res) => {
            const data = res.data.data;

            const cart_items_count = Number(global.cart_items_count) + 1;
            dispatch({type: "ORDER-ID", payload: data.order_id})
            dispatch({type: 'ORDER-CUSTOMER', payload: data.customer_id ?? 0});
            dispatch({type: "ORDER-TIME", payload: moment().format('YYYY-MM-DD HH:mm')})
            dispatch({type: 'CART-ITEMS-COUNT', 'payload': cart_items_count})
            dispatch({type: "SHOW-SUCCESS", payload: 'Item added into cart successfully.'})

            if (data.campaign_item && Object.keys(data.campaign_item).length > 0) {
                setCampaignItem(data.campaign_item)
                handleCampaignItem(data.order_id, data.campaign_item, cart_items_count);
            } else {
                setCampaignItem({})
            }

        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })
    }

    const handleCampaignItem = (order_id, campaign_item, cart_items_count) => {
        if (Object.keys(campaign_item).length > 0 && campaign_item['item_quantity'] > 0) {
            let discount_type = (campaign_item['camp_discount_type'] == 'exact_discount') ? '$' : '%'

            const data = {
                pcl_id: global.pcl.id,
                order_id: order_id,
                item_id: campaign_item['camp_item_id'],
                category_id: campaign_item['camp_category_id'],
                price_id: campaign_item['price_id'],
                price_detail_id: campaign_item['price_detail_id'],
                quantity: campaign_item['item_quantity'],
                request_type: 'item-add-to-cart',
            }

            Swal.fire({
                toast: true,
                title: 'Congrats!',
                html: "<div>" +
                    "You have an extra discounted item with this item purchase." + "<br>" +
                    "<span class='text-success'>Item Name: " + campaign_item['item_title'] + " </span><br>" +
                    "<span class='text-danger'>Discount: " + campaign_item['camp_discount'] + discount_type + " </span><br>" +
                    "<span class='text-warning'>Item Price: $" + campaign_item['camp_item_regular_price'] + " </span><br>" +
                    "<span class='text-info'>Discount Quantity: " + campaign_item['item_quantity'] + " </span><br>" +
                    "</div>",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1daf52',
                cancelButtonColor: '#b49311',
                confirmButtonText: 'Yes, Add Item',
                width: '600px'
            }).then((result) => {
                if (result.isConfirmed) {
                    Api.post(`client/order-details`, data, (res) => {
                        const data = res.data.data
                        dispatch({type: 'CART-ITEMS-COUNT', 'payload': Number(cart_items_count) + 1})
                        dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
                    }, (errors) => {
                        dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
                    })
                } else {
                    setCampaignItem({})
                }
            })

        }
    }

    function renderCategory(category) {
        if (category.categories === undefined || category.categories.length == 0 && category.items.length == 0) {
            return null;
        }

        return (
            <>
                {(category.path && category.path !== '' && category.path !== ' ')&&
                    <Row className='mt-2'>
                        <Col>
                            <h2 className='bb-1px'>{category.path}</h2>
                        </Col>
                    </Row>
                }

                {category.items && category.items.map(item => {
                    return (
                        <Col sm={6} key={item.id} className='mb-2'>
                            <div className='bg-light1 p-2 b-radius-4x height-100'>
                                <Row>
                                    <Col md={2}>
                                        <div className='d-flex justify-content-center'>
                                            <div className='item-img-wrapper'>
                                                <div className='item-img'>
                                                    <img src={(item.images && item.images.url) ? item.images.url : pd_image}/>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={7}>
                                        <div className='item-detail mb-3'>
                                            <div className='fw-bold fs-22x'> {item.name}</div>
                                            <div className='fs-13x'> {item.description}</div>

                                            <div className='fw-bold fs-16x d-inline'>
                                                {(item.prices && item.prices.discount_price && item.prices.discount_title) ?
                                                    <div>
                                                        <del>${Number(item.prices.regular_price).toFixed(2)}</del>&ensp;
                                                        <span className='text-green'>${Number(item.prices.applied_price).toFixed(2)}</span>
                                                        <span className='text-danger'> ({item.prices.discount_title})</span>
                                                        {(item.prices.description) &&
                                                            <span className='text-dark-light fs-13x'> ({item.prices.description})</span>
                                                        }
                                                    </div>
                                                    :
                                                    <>${Number(item.prices.regular_price).toFixed(2)} &ensp;</>
                                                }
                                            </div>
                                            <a href={`${category.path}/${item.slug}`}
                                               className='d-inline text-decoration-none'>View details</a>
                                        </div>
                                    </Col>
                                    <Col className='cart-button'>
                                        <div className='buttons'>
                                            <div className=''>
                                                {/*<div className='d-inline'>Quantity:</div>*/}
                                                <select className='form-select form-select-sm mb-2 d-inline' style={{maxWidth: '92px'}}
                                                onChange={(e) => setQuantity(e.target.value)}>
                                                    {/*<option selected value="1">1</option>*/}
                                                    {(() => {
                                                        let elements = [];
                                                        for (let i = 1; i <= 20; i++) {
                                                            if (i < item.min_qty_per_order || item.sell_as_single_item_per_line > i) continue;
                                                            if (i > item.max_qty_per_order || i > item.max_sellable_qty) {
                                                                break;
                                                            }

                                                            elements.push(<option key={i} value={i}>{i}</option>);

                                                            if ((item.sell_as_single_item_per_line || item.sell_as_single_item_per_order)) {
                                                                break;
                                                            }
                                                        }
                                                        return elements;
                                                    })()}
                                                </select>
                                            </div>

                                            <button className='btn btn-success btn-sm' onClick={() => handleCheckProperties(category.id, item)}>Add To Cart</button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    )
                    }
                )}

                {category.categories && category.categories.map(subCategory => (
                    <React.Fragment key={subCategory.id}>
                        {renderCategory(subCategory)}
                    </React.Fragment>
                ))}
            </>
        );
    }

    const handleLoadCartProperties = () => {
        const qryParams = '?pcl_id=' + 3 + '&category_id=' + 1 + '&request_type=item-cart-properties'
        Api.get(`client/pos` + qryParams, (res) => {
                const data = res.data.data
                setProperties(data)
            },
            (errors) => {
                // dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
            })
    }

    // Render main content
    return (
        <Container className='pt-3'>
            <div className='d-flex flex-wrap gap-3 mb-4'>
                {categories && categories.map((category, cIndex) => {
                    let active = ''
                    if (category.slug === selectedRootCategory) {
                        active = 'active'
                    }
                    return (
                        <div key={cIndex} className='flex-1 category'>
                            <a href={`/${category.slug}`} onClick={(e) => { e.preventDefault(); loadSelectedCategory(category.slug); }}
                               className={`btn w-100 btn-outline-success btn-category ${active}`}>
                                {category.name}
                            </a>
                        </div>
                    )
                })}
            </div>

            <Row>
                {/* Load category tree */}
                
                
                {/* Load single item */}
                {selectedCategory && selectedCategory?.categories?.length == 0 && selectedCategory?.items?.length == 1 ? ( 
                    <ItemDetails path={selectedCategory.path + '/' + selectedCategory.items[0].slug} setQuantity={setQuantity} handleCheckProperties={handleCheckProperties}/>
                ) : (
                    selectedCategory && renderCategory(selectedCategory)
                )}
            </Row>

            {(!categories || Object.entries(categories).length === 0) &&
                <div className='d-flex justify-content-center fw-bold fs-19x'>No categories or items found.</div>
            }
        </Container>
    )
}