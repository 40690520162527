
export default class URL {
    static accessTypes = ['pos', 'kiosk'];

    static baseUrl() {
        const url = window.location.href;
        const urlParts = /^(?:\w+\:\/\/)?([^\/]+)([^\?]*)\??(.*)$/.exec(url);
        const base_url = urlParts[1];
        // const base_url = 'fecmanager-london.client.onelinkpos2.com';
        // const base_url = 'fecmanager-lahore.client.onelinkpos2.com';
        // const base_url = 'xotish-london.client.onelinkpos2.com';
        // const base_url = 'playtopia-vaughan.client.onelinkpos2.com';
        return base_url;
    }

    static domain() {
        const url = window.location.href;
        const urlParts = /^(?:https?:\/\/)?(?:www\.)?([^\/:]+)([^\?]*)\??(.*)$/.exec(url);
        const domain = urlParts[1];     
        return domain;
    }

    static redirectUrl() {
        return window.location.origin;
    }

    static urlPath() {
        const urlPath = window.location.pathname;
        const trimmedPath = urlPath.replace(/^\/|\/$/g, "");
        return trimmedPath;
    }

    static accessType() {
        const path = this.urlPath();
        const pathArr = path.split('/')
        return pathArr[0] || '';
    }

    static getRedirectBase() {
        let red_base = '';
        const accessType = this.accessType();
        if (accessType === 'kiosk') {
            red_base = '/kiosk';
        }
        return red_base;
    }

    static urlRequest() {
        const path = this.urlPath();
        const pathArr = path.split('/')
        const newPathArr = pathArr.slice(1)
        return newPathArr.join('/');
    }

    static urlQueryParams(param = null) {
        const searchParams = new URLSearchParams(window.location.search);
        if (param) {
            return searchParams.get(param);
        } else {
            return decodeURIComponent(searchParams.toString());
        }
    }

}
