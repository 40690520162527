import React, {useContext, useEffect, useState} from "react";
import logo_image from "../images/playtopia-logo-1.png"
import {GlobalContext} from "./_services/global-context";
import {EmojiSmile} from "react-bootstrap-icons";
import URL from "./_services/routes";
import Api from "./_services/api_service";
import {cloneDeep} from "lodash";

export default function PaymentConfirmation() {
    const [global, dispatch] = useContext(GlobalContext);
    const [invoice, setInvoice] = useState('');

    const order_id = URL.urlQueryParams('order_id')
    const payment_id = URL.urlQueryParams('payment_id')
    useEffect(() => {
        handleGetOrderInvoice()
    }, []);

    const handleGetOrderInvoice = () => {
        const query = `pcl_id=${global.pcl.id}&payment_id=${payment_id || ''}`;
        Api.get(`client/orders/${order_id}/invoice?${query}`, (res) => {
            const data = res.data.data
            setInvoice(data.invoice)
            dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        });
    }

    const handleUpdateWaiver = () => {
        dispatch({type: 'RETURNING-URL', payload: '/waiver-sign'})
        if (global.user && global.user.id && global.token) {
            window.location.assign('/kiosk/waiver-sign?waiver=update')
        } else {
            window.location.assign('/kiosk/login?waiver=update')
        }
    }

    return (
        <div className=''>
            <div className='d-flex justify-content-center align-items-center'>
                <div className='text-center'>
                    <div style={{width: '253px'}} className='mb-4 m-auto'>
                        <EmojiSmile className='text-light-green mt-5' style={{fontSize: '77px'}}/>
                    </div>
                    <div className='mt-2 m-auto'>
                        <h3 className=''>Order Completed!</h3>
                        <h3 className='text-light-green'>Thank you, for submitting your order payment.</h3>
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-center'>
                <div style={{minWidth: '50%'}}>
                    <div className='inner-heading mt-5 text-center fs-26x'>Your order Invoice</div>
                    <div className='mt-2 text-center' dangerouslySetInnerHTML={{__html: invoice}}></div>
                </div>
            </div>


            <div className='w-75 m-auto'>
                Thank you for your booking! To prepare for your visit, everyone must sign a waiver before entering the
                facility.
                Save time and avoid waiting by completing it online now. If your booking includes an event, you can also
                invite guests,
                manage your guest list, and send invitations using our RSVP Manager. Click the links below to complete
                these steps and
                ensure a smooth and enjoyable experience!

                <div className='d-flex justify-content-center mt-4'>
                    <a className='btn btn-primary btn-md me-3 mb-3'
                       href={`/kiosk/waiver-sign?waiver=new`}>SIGN YOUR WAIVER</a>

                    <a className='btn btn-success btn-md me-3 mb-3'
                       href={`/rsvp?order-id=${order_id}`}>MANAGE RSVP</a>
                </div>
            </div>

        </div>
    )
}