
/**
 * config.js
 * 
 * This file contains configuration settings for the admin application.
 * It includes a function to determine the appropriate API endpoint based on the environment.
 */

/**
 * Returns the appropriate API endpoint based on the environment.
 * 
 * The function first checks if the `REACT_APP_API_URL` environment variable is defined,
 * which is useful for local development. If not, it falls back to a dynamic URL-based logic
 * to determine the endpoint for different deployment environments.
 * 
 * @returns {string} The API endpoint URL.
 */
const getApiEndpoint = () => {
    // Use environment variable if defined (for local development)
    if (process.env.REACT_APP_API_URL) {
      return process.env.REACT_APP_API_URL;
    }
  
    // Fallback to dynamic URL-based logic for deployments
    const hostname = window.location.hostname;
    
    if (hostname.includes('onelinkpos2.com')) {
      // Test/Staging environment
      return 'https://api2.onelinkpos2.com/api';
    } else if (hostname.includes('onelinkpos.com')) {
      // Production environment
      return 'https://api.onelinkpos.com/api';
    } else {
      // Default for unknown or local environments
      //Please use .env file for local development and add the following line to the file if you want a different API URL
      //REACT_APP_API_URL=http://localhost:9000/api
      return 'http://localhost:8000/api';
    }
  };
 
  // Export the API endpoint
  const API_ENDPOINT = getApiEndpoint();
  export default API_ENDPOINT;
  