import React, {useEffect, useState, useContext} from "react";
import {Container, Row, Col, Button} from "react-bootstrap";
import {GlobalContext} from "../_services/global-context";
import Api from "../_services/api_service";
import {useNavigate} from 'react-router-dom';
import placeholderImage from "../../images/product-dummy-image-1.jpg"; // Import placeholder image

export default function ItemDetails({path, setQuantity, handleCheckProperties}) {
    const [global, dispatch] = useContext(GlobalContext);
    const [itemDetails, setItemDetails] = useState(null);
    const [category, setCategory] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch item details from API
        fetchItemDetails();
    }, [path]);

  const fetchItemDetails = () => {
    const pclId = global.pcl.id; // Use pcl_id from global context
    Api.get(
        `client/categories?pcl_id=${pclId}&category=${path}`,
        (response) => {
          const {items} = response.data || {};
          if (items && items.length > 0) {
            setCategory(response.data);
            setItemDetails(items[0]); // Set the first item from the response
          } else {
            setItemDetails(null);
          }
          setLoading(false);
        },
        (error) => {
          console.error("Failed to fetch item details:", error);
          setLoading(false);
        }
    );
  };

    const addToCart = () => {
        navigate(`/cart/add?category_id=${category.id}&item_id=${itemDetails.id}&quantity=1`);
    };

    if (loading) {
        return (
            <Container className="py-5 text-center">
                <h4>Loading item details...</h4>
            </Container>
        );
    }

    if (!itemDetails) {
        return (
            <Container className="py-5 text-center">
                <h4>Item not found</h4>
            </Container>
        );
    }

    // Placeholder secondary images
    const secondaryImages = [placeholderImage, placeholderImage, placeholderImage];

    return (
        <Container className="py-5">
            {/* Mobile Main Image Section */}
            <div className="d-block d-md-none">
                <img
                    src={itemDetails.images?.url || placeholderImage}
                    alt={itemDetails.name}
                    className="img-fluid mb-4"
                />
            </div>

            <Row>
                {/* Desktop Images Section */}
                <Col md={6} className="d-none d-md-block">
                    <img
                        src={itemDetails.images?.url || placeholderImage}
                        alt={itemDetails.name}
                        className="img-fluid mb-4"
                    />
                    <Row className="g-2">
                        {secondaryImages.map((image, index) => (
                            <Col xs={4} key={index}>
                                <img
                                    src={image}
                                    alt={`Secondary ${index + 1}`}
                                    className="img-fluid border"
                                />
                            </Col>
                        ))}
                    </Row>
                </Col>

                {/* Text Section */}
                <Col md={6}>
                    <h2 className="mb-3">{itemDetails.name}</h2>
                    <h4 className="text-success mb-4">
                        Price: ${itemDetails.prices?.applied_price.toFixed(2)}
                    </h4>
                    <strong>Description:</strong>
                    <p className="mb-4">{itemDetails.description || "No description available."}</p>

                    <strong>Features:</strong>
                    <p className="mb-4" dangerouslySetInnerHTML={{__html: itemDetails.features || ""}}></p>


                    <div className='d-flex gap-3'>
                        <div>
                            <select className='form-select form-select-md mb-2 d-inline' style={{maxWidth: '92px'}}
                                    onChange={(e) => setQuantity(e.target.value)}>
                                {/*<option selected value="1">1</option>*/}
                                {(() => {
                                    let elements = [];
                                    for (let i = 1; i <= 20; i++) {
                                        if (i < itemDetails.min_qty_per_order || itemDetails.sell_as_single_item_per_line > i) continue;
                                        if (i > itemDetails.max_qty_per_order || i > itemDetails.max_sellable_qty) {
                                            break;
                                        }

                                        elements.push(<option key={i} value={i}>{i}</option>);

                                        if ((itemDetails.sell_as_single_item_per_line || itemDetails.sell_as_single_item_per_order)) {
                                            break;
                                        }
                                    }
                                    return elements;
                                })()}
                            </select>
                        </div>

                        <Button variant="success" className="mb-3"
                                onClick={() => handleCheckProperties(category.id, itemDetails)}>Add to Cart</Button>
                    </div>

                    <div>
                        <h5>Terms & Conditions</h5>
                        <p>No specific terms and conditions provided.</p>
                    </div>
                </Col>
            </Row>

            {/* Mobile Secondary Images Section */}
            <div className="d-block d-md-none mt-4">
                <Row className="g-2">
                    {secondaryImages.map((image, index) => (
                        <Col xs={4} key={index}>
                            <img
                                src={image}
                                alt={`Secondary ${index + 1}`}
                                className="img-fluid border"
                            />
                        </Col>
                    ))}
                </Row>
            </div>
        </Container>
    );
}
